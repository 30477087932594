/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import LargeQuote from "../../src/components/LargeQuote";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    em: "em",
    a: "a",
    strong: "strong",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Why follow your passion is bad advice"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "You gotta find what you love"), " - this is what Steve Jobs says in his infamous ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=Hd_ptbiPoXM"
  }, "speech\nat a Stanford graduation ceremony"), "."), "\n", React.createElement(_components.p, null, "I have to admit I've also watched this video and back in the day I found it insipring -\nuntil I read - ", React.createElement(_components.em, null, "So good they can't ignore you"), " by Cal Newport. In fantastic book\nthe author argues with a very sharp logic ", React.createElement(_components.strong, null, "why Steeve Jobs was wrong"), " and why Steve Jobs\n", React.createElement(_components.strong, null, "never even followed his own advice"), "."), "\n", React.createElement(_components.p, null, "In this essay I try to apply the principles described in the book direct to software\nengineering and what I think it means software engineers."), "\n", React.createElement(_components.h2, null, "Steve Jobs never followed his own \"passion advice\""), "\n", React.createElement(LargeQuote, {
    text: "When Steve Jobs started to go to college he wasn't a tech nerd - he was more into\nhistory and Zen buddhism."
  }), "\n", React.createElement(_components.p, null, "This is where his passions lied. So if he had followed his\nown advice of following his already-existing passions he would have probably\nbecome a ", React.createElement(_components.strong, null, "full-time Zen practitioner"), "."), "\n", React.createElement(_components.p, null, "It was only when personal computers started to get traction that he saw the opportunity\nto sell pre-build machines to a computer store in San Francisco when Apple was founded.\nInitially the plan was not to take over the world, but to make a quick buck."), "\n", React.createElement(_components.p, null, "Later Steve Jobs become one of the most influential and inspiring business leaders\nof all times and I think it is safe to say that he had a passion for what he did.\nOtherwise he would have retired early. So it ", React.createElement(_components.strong, null, "seems like Steve Jobs did not have a\npre-existing passion for computers to begin with"), " but rather developed his passion as\nApple took off."), "\n", React.createElement(_components.h3, null, "Most people's passions don't relate to business"), "\n", React.createElement(_components.p, null, "Let's think about the people you and I know. What kind of passions do they have?\nHmm, maybe music, travel, football? The vast majority of people don't have\na hobby that ", React.createElement(_components.strong, null, "relates to business or entrepreneurship"), "."), "\n", React.createElement(LargeQuote, {
    text: "If everyone were to follow his passion, you'd have an awful lot of yoga retreats."
  }), "\n", React.createElement(_components.p, null, "Some people like you and me for example like computers - but this is also rather the exception.\n", React.createElement(_components.strong, null, "Most people actually hate computers"), ".\nThey only use them because using computers is oftentimes the cheapest and most effective\nway of getting something done."), "\n", React.createElement(_components.h3, null, "Noone cares about your passion. People care about their own benefit"), "\n", React.createElement(_components.p, null, "The fundamental truth is that nobody cares about passions. In fact, why would anyone\nexcept your family and friends care about you at all?"), "\n", React.createElement(LargeQuote, {
    text: "If you go and get bread from your bakery, do you care whether the baker has a passion\nfor it? Not really. You probably only care whether the bread is good or not."
  }), "\n", React.createElement(_components.p, null, "This all comes down to a trivial - yet fundamental insight."), "\n", React.createElement(LargeQuote, {
    text: "The world doesn't owe you a life filled with privileges and success.\nPeople mostly care about themselves and the only way to achieve success and privileges\nis if you solve other people's problems."
  }), "\n", React.createElement(_components.p, null, "I bet you agree with that. But here's the thing: while this might be extremely easy\nto understand on an abstract level, ", React.createElement(_components.strong, null, "people don't act on this insight"), "."), "\n", React.createElement(LargeQuote, {
    text: "Today you got an entire generation of young people\nbrainwashed by so-called Instagram influencers who tell you every day that you are a beautiful and unique snowflake."
  }), "\n", React.createElement(_components.p, null, "They ", React.createElement(_components.strong, null, "make it look as if you deserve to be noticed"), " because each and every one of us\nis so special and that you just need to follow your passion and eventually you will\nachieve what you want."), "\n", React.createElement(_components.p, null, "This line of argumentation is obviously totally flawed - but you know these guys\nare marketers. They do what works."), "\n", React.createElement(LargeQuote, {
    text: "Oftentimes marketers don't care if they give practical advice, but they rather\nwant to build engagement."
  }), "\n", React.createElement(_components.p, null, "Because with engagement you grow and when you grow to a certain size, you have a brand. And if you have a brand, it is easier to sell\nsomething to people."), "\n", React.createElement(_components.h3, null, "What should you do instead of following your (pre-existing) passion?"), "\n", React.createElement(_components.p, null, "I bet you've probably already had that insight before, but the question is what is\nthe consequence for you as a person? So while this insight is trivial on an abstract\nlevel, how does it influence what you do on a daily basis?"), "\n", React.createElement(_components.p, null, "Well people have problems and they need solutions to these problems."), "\n", React.createElement(LargeQuote, {
    text: "The only way to convince people to give you money is by solving their problems\nin an effective way. To solve other people's problems in an effective way,\nyou need to produce something for which they are willed to pay for."
  }), "\n", React.createElement(_components.p, null, "Television and Instagram makes us believe that anyone without any special skill\ncan build a company. ", React.createElement(_components.em, null, "Just buy my $89 course and become an expert in ???"), ". Yeah,\nyeah, of course."), "\n", React.createElement(_components.p, null, "To provide values to others, ", React.createElement(_components.strong, null, "you need to be good\nat something they are not"), ". Otherwise your potential customers would not have that problem\nin the first place. If it is something easy they would have already found a solution\nfor it by either fixing it by themselves or buying an already existing product."), "\n", React.createElement(LargeQuote, {
    text: "In other words to even get in the position to solve other people's problems,\nyou need to be very skilled at something."
  }), "\n", React.createElement(_components.p, null, "Consequently - as Cal Newport argues - your focus\nshould be to get extremely good at whatever you specialize in and continually\nimprove the quality of what you are producing."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "You see the notion of production is essential here"), "."), "\n", React.createElement(LargeQuote, {
    text: "A lot of people fall for the academics trap. They just read and read and read about how to do it, instead\nof getting their hands dirty. Like so you might become a professor, but not a successful\nbusiness man."
  }), "\n", React.createElement(_components.p, null, "To get into execution mode you need to ", React.createElement(_components.strong, null, "regularly create something and then gather feedback\nat something that is really hard"), "."), "\n", React.createElement(_components.p, null, "This is hard because from our early childhood onwards we are indirectly taught that ", React.createElement(_components.strong, null, "knowing something is more or less enough"), ".\nIn college and high-school you try to get as much knowledge and your head as you can - but you just don't get into the habit of producing something regularly."), "\n", React.createElement(_components.h3, null, "The tape doesn't lie"), "\n", React.createElement(_components.p, null, "Musicians - as Cal Newport argues - say that ", React.createElement(_components.em, null, "the tape doesn't lie"), " - meaning that ultimately your success will depend on the quality of what you produce."), "\n", React.createElement(_components.p, null, "While the overall statement makes total sense to me, it seems that also other factors\nsuch as your ability to market yourself that seem to count a lot. Otherwise I cannot explain\nall these ", React.createElement(_components.em, null, "get rich quick"), " gurus on social media who are able to successfully sell\nsome overpriced courses with little to no value."), "\n", React.createElement(_components.p, null, "However, it is clear that in such cases the guru's success is not sustainable because\nlong term you just cannot fake it that long. Eventually the truth will come out."), "\n", React.createElement(_components.h3, null, "Building end-to-end skills"), "\n", React.createElement(_components.p, null, "My personal takeaway after reading the book and taking into account other books I've\nread is that I will strive to develop more end-to-end skills."), "\n", React.createElement(LargeQuote, {
    text: "Ultimately people care about what benefit a particular product offers to them.\nAnd to even be able to offer a product to them, you first need to make the product and then market it."
  }), "\n", React.createElement(_components.p, null, "The thing is that I think ", React.createElement(_components.strong, null, "building complementary skills is more approachable than trying to become the absolute best person in one particular\nfield"), ". A ", React.createElement(_components.strong, null, "unique or uncommon"), " combination of skills can make you very valuable long-term\nand this is why I think it is so essential to also look into marketing and sales - even if\nyou are just the \"tech guy\"."), "\n", React.createElement(_components.p, null, "Chances are that as you get really good at at something, you will also develop\na passion as you go - just like Steve Jobs did."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
